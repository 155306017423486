import { Page } from "./Page";
import { toggleClass } from "@tawenda-npm/tawenda-utils/lib/index";

export class FaqPage extends Page {
  public async start(): Promise<void> {
    await super.start();

    const paneClassname = "faq-subject-element";
    const selectors: NodeListOf<HTMLElement> =
      document.querySelectorAll("[data-faq-target]");
    const panes: NodeListOf<HTMLElement> = document.querySelectorAll(
      ".faq-subject-element"
    );

    selectors?.forEach((selector: HTMLElement) => {
      selector.addEventListener("click", (e: Event) => {
        selectors.forEach((el: HTMLElement) => {
          el.isEqualNode(selector)
            ? el.classList.add("active")
            : el.classList.remove("active");
        });

        const paneToShow: HTMLElement = document.getElementById(
          selector.dataset.faqTarget
        );
        panes?.forEach((pane: HTMLElement) => {
          pane.isEqualNode(paneToShow)
            ? (pane.style.display = "block")
            : (pane.style.display = "none");
        });
        paneToShow.scrollIntoView({ behavior: "smooth" });
      });
    });

    /*
      if($(".faq-subject-element:visible").length > 0) {
        $(".faq-subject-element:visible").fadeOut(function() {
          $element.fadeIn(100, function () {
            document.getElementById(e.target.dataset.faqTarget).scrollIntoView({behavior: 'smooth' });
          });
        });
      } else {
        $element.fadeIn(100, function() {
          document.getElementById(e.target.dataset.faqTarget).scrollIntoView({behavior: 'smooth'});
        });
      }
    });
    * */
  }
}
