import { toggleClass, toggleText } from "@tawenda-npm/tawenda-utils";
import { tns } from "tiny-slider";
import { Page } from "./Page";
import initTopCategoriesCarousel from "../libs/topCategories";

export class VideosListPage extends Page {
  public async start(): Promise<any> {
    await super.start();
    initTopCategoriesCarousel();
  }
}
