import { Page } from "./Page";
import { DropDownHandler } from "../libs/DropDown";
import Cookies from "js-cookie";

export class UserPlaylistsPage extends Page {
  public async start(): Promise<void> {
    await super.start();

    document
      .querySelectorAll("[data-delete-playlist-id]")
      .forEach(async (el: HTMLElement) => {
        el.addEventListener("click", async (e: Event) => {
          e.preventDefault();

          const formData = new FormData();
          formData.append("playlist_pk", el.dataset.deletePlaylistId);

          const response = await fetch(el.dataset.playlistDeleteUrl, {
            headers: { "X-CSRFToken": Cookies.get("csrftoken") || "" },
            method: "post",
            body: formData,
          });

          if (response.ok) {
            const result = await response.json();
            if (result.success) {
              const elementToRemove = el.closest(
                el.dataset.parentSelectorToHideOnSuccess
              );
              elementToRemove?.remove();
            }
          }
        });
      });

    document
      .querySelectorAll("[data-remove-from-playlist-id]")
      .forEach(async (el: HTMLElement) => {
        el.addEventListener("click", async (e: Event) => {
          e.preventDefault();

          const formData = new FormData();
          formData.append("playlist_pk", el.dataset.removeFromPlaylistId);

          const response = await fetch(el.dataset.playlistRemoveUrl, {
            headers: { "X-CSRFToken": Cookies.get("csrftoken") || "" },
            method: "post",
            body: formData,
          });

          if (response.ok) {
            const result = await response.json();
            if (result.success) {
              const elementToRemove = el.closest(
                el.dataset.parentSelectorToHideOnSuccess
              );
              elementToRemove?.remove();
            }
          }
        });
      });
  }
}
