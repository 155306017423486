import { tns } from "tiny-slider";

const topVideosSlider = () => {
  const sliderElement: HTMLElement =
    document.getElementById("top-videos-slider");

  if (sliderElement) {
    tns({
      container: sliderElement,
      loop: true,
      autoplay: true,
      autoplayTimeout: 2000,
      items: 1,
      slideBy: 1,
      mouseDrag: true,
      nav: false,
      controls: false,
      gutter: 10,
      autoplayButtonOutput: false,
    });
  }
};

export default topVideosSlider;
