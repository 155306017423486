import { Page } from "./Page";
import { tns } from "tiny-slider/src/tiny-slider";
import initTopCategoriesCarousel from "../libs/topCategories";

export class HomePage extends Page {
  public async start(): Promise<void> {
    await super.start();

    initTopCategoriesCarousel();

    const carouselTopVideos = document.getElementById("top-videos-carousel");
    if (carouselTopVideos) {
      tns({
        container: carouselTopVideos,
        items: 1,
        slideBy: 1,
        autoplay: true,
        controls: false,
        nav: false,
        autoplayButtonOutput: false,
        mouseDrag: true,
        responsive: {
          768: {
            items: 2,
          },

          1024: {
            items: 4,
          },
        },
      });
    }
  }
}
