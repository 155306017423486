import { Page } from "./Page";
import { PlayerHandler } from "../libs/Player";
import { Likes } from "../libs/Likes";
import { Favorites } from "../libs/Favorites";
import { DropDownHandler } from "../libs/DropDown";
import { Playlist } from "../libs/Playlist";
import topVideosSlider from "../libs/topVideosSlider";

export class VideoDetailPage extends Page {
  public async start(): Promise<any> {
    await super.start();

    const partiallyDisabledPlayer: HTMLElement = document.querySelector(
      "#video-player.partially-disabled"
    );
    const player: HTMLElement = document.querySelector(
      "[data-player-get-settings-url]"
    );
    if (player) {
      const redirectTargetUrl = player.dataset.alternateVideoUrl;
      await new PlayerHandler(player as HTMLElement).init();
      if (redirectTargetUrl) {
        player.addEventListener("ended", () => {
          window.location.href = redirectTargetUrl;
        });
        setTimeout(() => {
          window.location.href = redirectTargetUrl;
        }, 10000);
      }
    }

    topVideosSlider();

    const my18passSelector = document.querySelector(
      "[data-my18pass-free-video]"
    );
    my18passSelector?.addEventListener("click", (e: Event) => {
      (document.querySelector("my18pass-widget") as any).openDialog(e);
    });

    const likeElement: HTMLElement = document.querySelector(
      "[data-like-container]"
    );
    if (likeElement) {
      const likeHandler = new Likes(likeElement);

      likeElement.addEventListener("click", async (e: Event) => {
        e.preventDefault();
        await likeHandler.like();
      });
    }

    document
      .querySelectorAll("[data-favorite-get-url]")
      .forEach(async (el: HTMLElement) => {
        await new Favorites(el).init();
      });

    const playlistDropDown = document.getElementById("dropdown-video-playlist");
    if (playlistDropDown) {
      new DropDownHandler(playlistDropDown);

      const playlistWrapper: HTMLElement = playlistDropDown.querySelector(
        "[data-playlist-wrapper]"
      );
      if (playlistWrapper) await new Playlist(playlistWrapper).init();
    }

    const downloadUrlsDropDown = document.getElementById("download-container");
    if (downloadUrlsDropDown) {
      new DropDownHandler(downloadUrlsDropDown);
    }
    const displayMoreButton = document.querySelector("[data-display-more]");
    const displayLessButton = document.querySelector("[data-display-less]");
    if(displayMoreButton && displayLessButton) {
      displayMoreButton.addEventListener("click", async (e: Event) => {
        e.preventDefault();
        document
            .querySelectorAll("[data-show-if-display-more]")
            .forEach((el: Element) => {
              (el as HTMLElement).style.display = "flex";
            });
      });
      displayLessButton
          .addEventListener("click", async (e: Event) => {
            e.preventDefault();
            document
                .querySelectorAll("[data-show-if-display-more]")
                .forEach((el: Element) => {
                  (el as HTMLElement).style.display = "none";
                });
          });
    }
  }
}
