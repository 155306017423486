import { Page } from "./Page";
import { UserEdit } from "../libs/UserEdit";

export class UserEditPage extends Page {
  public async start(): Promise<any> {
    await super.start();

    new UserEdit("form.form").initValidator();
  }
}
